import { combineReducers } from 'redux';

import app from 'features/app/reducer';
import auth from 'features/auth/reducer';
import companies from 'features/companies/reducer';
import creditNotes from 'features/creditNotes/reducer';
import invoices from 'features/invoices/reducer';
import logistic from 'features/logistics/reducer';
import logisticInfo from 'features/logistic/reducer';
import marketplace from 'features/marketplace/reducer';
import modal from 'features/modal/reducer';
import products from 'features/products/reducer';
import sales from 'features/sales/reducer';
import supply from 'features/supply/reducer';
import entities from './entities';

const rootReducer = combineReducers({
  app,
  auth,
  companies,
  creditNotes,
  entities,
  invoices,
  logistic,
  logisticInfo,
  marketplace,
  modal,
  products,
  sales,
  supply,
});

export default rootReducer;
