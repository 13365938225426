export const COMPANIES_SET_CURRENT = 'Companies: set current company';
export const COMPANIES_USERS = 'Companies: users';
export const COMPANIES_USERS_ADD = 'Companies: add an users';
export const COMPANIES_USER_ROLES_GET = 'Companies: get user roles';
export const COMPANIES_USER_ROLES_SET = 'Companies: set user roles';
export const COMPANIES_TOGGLE_USER = 'Companies: enable/disable user';
export const COMPANIES_TOGGLE_COMPANY = 'Companies: enable/disable a company account';
export const COMPANIES_ADDRESSES = 'Companies: addresses';
export const COMPANIES_SET = 'Companies: set companies';
export const COMPANIES_TYPE_ADD = 'Companies: add companies of specifict type';
export const COMPANIES_ADD = 'Companies: add company';
export const COMPANIES_SET_FILTER = 'Companies: set filter';
export const COMPANIES_CLEAR_FILTER = 'Companies: clear filter';
export const COMPANIES_BILLING_ADDRESS = 'Companies: set billing address for current company';

export const COMPANIES_UPDATE_SUPPLIER_AREA_STATE = 'Companies: update supplier areas state';

export const COMPANIES_DISPATCH = "Companies: fetch a company's list of dispatch addresses";
export const COMPANIES_DISPATCH_ADD = 'Companies: create/update a new dispatch address for a company';
export const COMPANIES_DISPATCH_DELETE = "Companies: delete a company's dispatch address";

export const COMPANIES_DESTINATION = "Companies: fetch a company's list of destinations";
export const COMPANIES_DESTINATION_CREATE = 'Companies: create a new destination for a company';
export const COMPANIES_DESTINATION_UPDATE = 'Companies: update an existing destination of a company';
export const COMPANIES_DESTINATION_DELETE = "Companies: delete a company's destination";

export const COMPANIES_UPDATE_ONLINE_USERS = 'Companies: update the list of online users';

export const COMPANIES_UPDATE_EMAIL_PREFERENCES = "Companies: update a company's email notification preferences";

export const COMPANIES_SET_LINKED_BUYERS = 'Companies: set buyers linked to selected supplier';
