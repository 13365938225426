import * as Yup from 'yup';

/**
 * Login form validation
 * /login
 */
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!'),
  password: Yup.string()
    .min(8, 'Password has to be longer than 8 characters!')
    .required('Password is required!'),
});
