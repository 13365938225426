import * as ActionTypes from './actionTypes';

const initialState = {
  selectedSupplier: null,
  selectedCustomer: null,
  logisticInfo: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.LOGISTIC_INFO_SELECTED_SUPPLIER:
      return { ...state, selectedSupplier: payload };
    case ActionTypes.LOGISTIC_INFO_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: payload };
    case ActionTypes.LOGISTIC_INFO_ADD:
      return {
        ...state,
        logisticInfo: [...new Set(state.logisticInfo.concat(payload))],
      };
    case ActionTypes.LOGISTIC_INFO_REMOVE:
      return {
        ...state,
        logisticInfo: state.logisticInfo.filter((id) => !payload.includes(id)),
      };
    case 'RESET':
    case ActionTypes.LOGISTIC_INFO_RESET:
      return {
        ...state,
        logisticInfo: [],
        selectedCustomer: null,
        selectedSupplier: null,
      };
    default:
      return state;
  }
};
