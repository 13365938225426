export const LOGISTIC_ADDRESSES = 'Logistic: set addresses';
export const LOGISTIC_ADDRESSES_ADD = 'Logistic: add a new address';
export const LOGISTIC_DESTINATIONS = 'Logistic: set destinations';
export const LOGISTIC_TRANSPORTS = 'Logistic: set transports';
export const LOGISTIC_TRANSPORTS_ADD = 'Logistic: add a new transport';
export const LOGISTIC_TRUCKS = 'Logistic: set trucks';
export const LOGISTIC_TRUCKS_ADD = 'Logistic: add a new truck';
export const LOGISTIC_AREAS = 'Logistic: set logistic areas';
export const LOGISTIC_AREAS_ADD = 'Logistic: add a new logistic area';
export const LOGISTIC_ROUTES = 'Logistic: set logistic routes';
export const LOGISTIC_ROUTES_ADD = 'Logistic: add logistic routes';
export const LOGISTIC_SET_FILTER = 'Logistic: set filter';
export const LOGISTIC_CLEAR_FILTER = 'Logistic: clear filter';
export const ADDRESS_ADD = 'Logistic: add/update new address';
export const MAP_UPDATE_SELECTION = 'Logistic Network: update view selection filter on the map';
export const AREA_UPDATE_CENTER = 'Logistic Areas: update the center coordinates in the Areas Form';
export const AREA_UPDATE_MILE_RADIUS = 'Lotistic Areas: update the distance of the circle region in Areas Form';

// @TODO: Remove when we fully manage logistic info from logistics tab
export const LOGISTIC_INFO = 'Companies: fetch the list of logistic info from one or all customers';
export const LOGISTIC_INFO_ADD = 'Companies: create a new logistic info for a customer';
export const LOGISTIC_INFO_REMOVE = 'Companies: delete a customer logistic info';
