export const PRODUCTS_SPECIES = 'Products: species';
export const PRODUCTS_TYPES = 'Products: types';
export const PRODUCTS_VARIATIONS = 'Products: variations';
export const PRODUCTS_GRADES = 'Products: grades';
export const PRODUCTS_PACKINGS = 'Products: packings';
export const PRODUCTS_FISHING_METHODS = 'Products: fishing methods';
export const PRODUCTS_FISHING_AREAS = 'Products: fishing areas';
export const PRODUCTS_VARIABLE_WEIGHT = 'Products: variable weight products';
export const PRODUCTS_CERTIFICATES = 'Products: certificates';
export const PRODUCTS_CERTIFICATIONS = 'Products: certifications';
export const PRODUCTS_BRANDINGS = 'Products: brandings';
export const PRODUCTS_TRANSPORT_METHOD = 'Products: transportMethod';
export const PRODUCTS_NATURE = 'Products: nature';


export const PRODUCTS_SET_FILTER = 'Products: set filter';
export const PRODUCTS_LAST_FETCH = 'Products: all product data last fetch';
export const RESET_DATA = 'Products: reset app data';

export const PRODUCT_BLACKLIST_SET = 'Products: fetch products blacklist';
export const PRODUCT_BLACKLIST_ADD = 'Products: blacklist a new product';
export const PRODUCT_BLACKLIST_DELETE = 'Products: delete a product from the blacklist';
