import React, { Suspense } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { lazyLoadWithRetry } from 'core/lazyLoadWithRetry';
import ErrorBoundary from './features/app/containers/ErrorBoundaryContainer';
import ContentBoundary from './features/app/components/ContentBoundary/ContentBoundary';
import PageLoading from './features/app/components/AppLoading/PageLoading';
import Login from './features/auth/containers/LoginContainer';
import useDatadog from './features/app/hooks/useDatadog/useDatadog';

const AdminApp = lazyLoadWithRetry(
  () => import('./features/app/containers/AdminAppContainer' /* webpackChunkName: "js/admin/app" */),
  'adminApp',
);

const AdminRoot = ({ history }) => {
  useDatadog();

  return (
    <ErrorBoundary component={ContentBoundary}>
      <Suspense fallback={<PageLoading />}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route exact path="/login" component={Login} />
            <Route render={() => <AdminApp />} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
};

AdminRoot.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default Sentry.withProfiler(AdminRoot);
