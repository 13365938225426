import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { authLogin } from '../actions';
import { selectIsAuthenticated } from '../selectors';
import Login from '../components/Login/Login';

class LoginContainer extends Component {
  state = {
    email: '',
    password: '',
  };

  handleChange = ({ target }) => {
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const nextState = {
      credentials: {
        ...this.state.credentials,
        [name]: value,
      },
    };

    this.setState(nextState);
  };

  onSubmit = (data, actions) => {
    this.props.authLogin(data).catch((errMessage) => {
      actions.setStatus(errMessage);
      actions.setSubmitting(false);
    });
  };

  render() {
    const { isAuthenticated, location } = this.props;
    const { from } = location.state || { from: { pathname: '/dashboard' } };
    const { email, password } = this.state;

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return <Login initialValues={{ email, password }} onSubmit={this.onSubmit} />;
  }
}

LoginContainer.propTypes = {
  authLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, {
  authLogin,
})(LoginContainer);
