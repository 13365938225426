export const INVOICES_SET_INVOICES = 'Invoices: fetch invoices list data';
export const INVOICES_FETCH_INVOICE = 'Invoices: fetch invoice data';
export const INVOICES_UPDATE_INVOICE = 'Invoices: update an invoice';
export const INVOICES_SET_FILTER = 'Invoices: set filters for invoices list';
export const INVOICES_CLEAR_FILTER = 'Invoices: reset the filters for invoices list';

export const INVOICES_DOWNLOAD = 'Invoices: download the invoice';
export const INVOICES_ADD_OR_UPDATE = 'Invoices: add/update invoice(s)';
export const INVOICES_DELETE_INVOICE = 'Invoice: invoice has been deleted';
export const INVOICES_TOGGLE_FLAG = "Invoices: toggle the invoice's flag attribute to true/false";
export const INVOICES_UPDATE_SALE = 'Invoices: update the packing/qty/deliveryDate of a sale within an invoice';

export const INVOICES_SET_TYPES_OF_CHANGE = 'Invoices: sets all possible types of change of an invoice';
export const INVOICES_SET_REASONS_OF_CHANGE = 'Invoices: sets all possible reasons of change of an invoice';
