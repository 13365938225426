import * as ActionTypes from './actionTypes';

const initialState = {
  addresses: [],
  transports: [],
  trucks: [],
  logisticAreas: [],
  logisticInfos: [],
  destinations: [],
  filter: {
    addresses: null,
    areas: null,
  },
  map: {
    // defaults to 525 Ferry Road, @todo: change to user's current lcoation?
    center: { lat: 55.9679636, lng: -3.2331005 },
    address: {
      4: true,
      3: true,
      2: true,
    },
  },
  area: {
    // geographical center of Europe
    center: { latitude: 53.5775, longitude: 23.1061115 },
    mileRadius: 2.5,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.LOGISTIC_INFO_ADD:
      return {
        ...state,
        logisticInfos: [...new Set(state.logisticInfos.concat(payload))],
      };
    /** @TODO: Remove when we fully manage logistic info from logistics tab */
    case ActionTypes.LOGISTIC_INFO:
      return { ...state, logisticInfos: payload };
    case ActionTypes.LOGISTIC_INFO_REMOVE:
      return {
        ...state,
        logisticInfos:
          typeof payload === 'number'
            ? state.logisticInfos.filter((id) => id !== payload)
            : state.logisticInfos.filter((id) => !payload.includes(id)),
      };
    /** ----------------------------------------------- */
    case ActionTypes.LOGISTIC_ADDRESSES:
      return { ...state, addresses: payload };
    case ActionTypes.LOGISTIC_ADDRESSES_ADD:
      return {
        ...state,
        addresses: [...new Set(state.addresses.concat(payload))],
      };
    case ActionTypes.LOGISTIC_DESTINATIONS:
      return { ...state, destinations: payload };
    case ActionTypes.LOGISTIC_AREAS:
      return { ...state, logisticAreas: payload };
    case ActionTypes.LOGISTIC_AREAS_ADD:
      return {
        ...state,
        logisticAreas: [...new Set(state.logisticAreas.concat(payload))],
      };
    case ActionTypes.LOGISTIC_TRANSPORTS:
      return { ...state, transports: payload };
    case ActionTypes.LOGISTIC_TRANSPORTS_ADD:
      return {
        ...state,
        transports: [...new Set(state.transports.concat(payload))],
      };
    case ActionTypes.LOGISTIC_TRUCKS:
      return { ...state, trucks: payload };
    case ActionTypes.LOGISTIC_TRUCKS_ADD:
      return { ...state, trucks: [...new Set(state.trucks.concat(payload))] };
    case ActionTypes.LOGISTIC_SET_FILTER:
      return { ...state, filter: payload };
    case ActionTypes.LOGISTIC_CLEAR_FILTER:
      return { ...state, filter: initialState.filter };
    case ActionTypes.ADDRESS_ADD:
      return {
        ...state,
        addresses: [...new Set(state.addresses.concat(payload))],
      };
    case ActionTypes.AREA_UPDATE_CENTER:
      return { ...state, area: { ...state.area, center: payload } };
    case ActionTypes.AREA_UPDATE_MILE_RADIUS:
      return { ...state, area: { ...state.area, mileRadius: payload } };
    case ActionTypes.MAP_UPDATE_SELECTION:
      return {
        ...state,
        map: {
          ...state.map,
          [payload.type]: {
            ...state.map[payload.type],
            [payload.key]: !state.map[payload.type][payload.key],
          },
        },
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
