import styled from 'styled-components';

export const StyledApp = styled.div`
  background-color: ${props => props.theme.greyLight};
  min-height: 100vh;
  position: relative;
  padding-left: 80px;
`;

export const StyledMain = styled.div`
  padding: 1rem;
`;

export const StyledLoader = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.greyLight};

  h1 {
    color: #999;
    font-weight: 300 !important;
    font-size: 1.3rem;
  }

  .loading-logo {
    margin-top: -87px;
  }
`;
