/**
 *  All selectors for Authentication related things
 *
 */
import { createSelector } from 'reselect';

const getIsAuthenticated = (state) => state.auth.isAuthenticated;
const getCurrentUser = (state) => state.auth.user;

/**
 * Select the auth status of the user
 * Gives an boolean (true if user is authenticated)
 */
export const selectIsAuthenticated = createSelector(
  getIsAuthenticated,
  (isAuthenticated) => isAuthenticated && !!localStorage.getItem('token'),
);

/**
 * Select the authenticated user object
 * Gives and object with user info
 */
export const selectCurrentUser = createSelector(getCurrentUser, (currentUser) => currentUser);

/**
 * Select the ID of this user's preferred addresss
 * Gives a string with language info
 */
export const selectPreferredDestinationId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.selectPreferredDestination,
);
