import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Flex, Div, Col, Input, Button, P, Span,
} from '@rooser/boop-react';

import FormGroup from 'generic/FormGroup/FormGroup';
import { loginValidationSchema } from '../../validation';

const Login = ({ onSubmit, initialValues }) => (
  <Flex column horizontal="around" vertical="middle" fullHeight>
    <Col width="1-2">
      <h1 className="boop-text-center">Admin Login</h1>
      <P lead textAlign="center">
        Welcome back!
      </P>
      <hr />
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        render={({
          status,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          errors,
        }) => (
          <form onSubmit={handleSubmit} method="post">
            <FormGroup error={errors.email}>
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Email address"
                autoFocus
              />
            </FormGroup>
            <FormGroup error={errors.password}>
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
              />
            </FormGroup>
            {status && (
              <Div padding="p" noPadding={['bottom']} textAlign="center">
                <Span color="danger">{status}</Span>
              </Div>
            )}
            <Div textAlign="center" padding="p">
              <Button size="large" style="primary" type="submit" disabled={isSubmitting}>
                Sign in
              </Button>
            </Div>
          </form>
        )}
      />
      <br />
    </Col>
  </Flex>
);

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
};

export default Login;
