import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (history) {
      this.unlisten = history.listen(() => {
        this.setState({
          error: null,
        });
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.props;

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children, component: Component } = this.props;

    if (error && Component) {
      // render fallback UI
      return (
        <Component
          error={error}
          showReport={() => {
            Sentry.showReportDialog();
          }}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  history: null,
  user: undefined,
};

ErrorBoundary.propTypes = {
  component: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
  }),
};

export default ErrorBoundary;
