import * as ActionTypes from './actionTypes';

const initialState = {
  selectedSupplier: null,
  offers: [],
  sales: [],
  filters: {
    species: null,
    type: null,
    variation: null,
    grade: null,
    dispatchDate: null,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SUPPLY_SET_SELECTED_SUPPLIER:
      return { ...state, selectedSupplier: payload };
    case ActionTypes.SUPPLY_OFFERS:
      return { ...state, offers: payload };
    case ActionTypes.SUPPLY_OFFERS_ADD:
      return { ...state, offers: [...new Set(state.offers.concat(payload))] };
    case ActionTypes.SUPPLY_OFFERS_REMOVE:
      return { ...state, offers: state.offers.filter((o) => o !== payload) };
    case ActionTypes.SUPPLY_SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...payload } };
    case ActionTypes.SUPPLY_SALES:
      return { ...state, sales: payload };
    case ActionTypes.SUPPLY_SALES_ADD:
      return { ...state, sales: [...new Set(state.sales.concat(payload))] };
    case ActionTypes.SUPPLY_SALES_REMOVE:
      return { ...state, sales: state.sales.filter((id) => id !== payload) };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
