import axios from 'axios';
import humps from 'humps';
import has from 'lodash.has';
import { getToken, storeToken } from 'core/auth';
import configStore from 'core/configStore';
import { authLogout } from 'features/auth/actions';

/**
 * Send CSRF token to Laravel
 */
// const token = document.head.querySelector('meta[name="csrf-token"]') || global.mockToken;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.APP_URL;

// Store pending requests
const pending = {};
// Cancel token to cancel requests
const CancelToken = axios.CancelToken;

/**
 * Remove a pending request
 * @param {} config
 * @param {*} f
 */
const removePending = (config, f) => {
  // make sure the url is same for both request and response
  if (config && config.url) {
    const url = config.url.replace(config.baseURL, '/');
    // stringify whole RESTful request with URL params
    const flagUrl = `${url}&${config.method}&${JSON.stringify(config.params)}`;
    if (flagUrl in pending) {
      if (f) {
        f(); // abort the request
      } else {
        delete pending[flagUrl];
      }
    } else if (f) {
      pending[flagUrl] = f; // store the cancel function
    }
  }
};

/**
 * Checks if an error is JSON when axios is expecting a blob.
 *
 * @param {*} error
 * @returns {boolean}
 */
const isJsonErrorToBlobRequest = (error) => error.request
  && error.request.responseType === 'blob'
  && error.response.data instanceof Blob
  && error.response.data.type
  && error.response.data.type === 'application/json';

/**
 * Parses a blob error as JSON and rejects the Promise.
 *
 * @param {function} reject
 * @param {object} error
 * @returns {Promise}
 */
const parseBlobErrorAsJson = (reject, error) => {
  const reader = new FileReader();

  reader.onload = () => {
    const parsedError = { ...error };

    parsedError.response = JSON.parse(reader.result);

    if (has(parsedError.response, 'message')) {
      parsedError.message = parsedError.response.message;
    }

    reject(parsedError);
  };

  reader.onerror = () => {
    reject(error);
  };

  reader.readAsText(error.response.data);
};

/**
 * Intercept request
 *
 * - Add auth token to it
 * - Decamelize keys of data sent to backend
 */
axios.interceptors.request.use(
  (request) => {
    // you can apply cancel token to all or specific requests
    // e.g. except config.method == 'options'
    request.cancelToken = new CancelToken((c) => {
      removePending(request, c);
    });

    if (
      request.method === 'post'
      || request.method === 'POST'
      || request.method === 'put'
      || request.method === 'PUT'
      || request.method === 'patch'
      || request.method === 'PATCH'
    ) {
      if (request.headers['Content-Type'] !== 'multipart/form-data') {
        request.data = humps.decamelizeKeys(request.data);
      }
    }
    request.headers.Authorization = `Bearer ${getToken()}`;
    return request;
  },
  (error) => Promise.reject(error),
);

/**
 * Intercept response
 *
 * - Camelize keys of data received from backend
 */
axios.interceptors.response.use(
  (response) => {
    removePending(response.config);

    if (
      (response.headers['content-type']
        && response.headers['content-type'].match(/application\/json/g))
      || (response.headers['Content-Type']
        && response.headers['Content-Type'].match(/application\/json/g))
    ) {
      response.data = humps.camelizeKeys(response.data);
    }

    // Store new token if refreshed
    if (response.headers.authorization) {
      storeToken(response.headers.authorization.replace('Bearer ', ''));
    }

    return response;
  },
  (error) => {
    removePending(error.config);

    if (error.response && error.response.status === 401 && !error.response.data.login) {
      configStore.store.dispatch(authLogout());
      window.location = '/login';
    }

    if (isJsonErrorToBlobRequest(error)) {
      return new Promise((_, reject) => parseBlobErrorAsJson(reject, error));
    }

    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }

    // return empty object for aborted request
    return Promise.resolve({});
  },
);

/**
 * Clear the pending requests
 */
axios.clear = () => {
  Object.keys(pending).forEach((e) => {
    if (pending[e]) {
      pending[e]();
      delete pending[e];
    }
  });
};

export default axios;
