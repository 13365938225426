import React from 'react';
import PropTypes from 'prop-types';
import { StyledFormGroup, StyledError, StyledHelpText } from './styled';

const FormGroup = ({
  children, error, errorClassName, helpText, paddingRight,
}) => (
  <StyledFormGroup paddingRight={paddingRight}>
    {children}
    {error ? (
      <StyledError className={errorClassName}>{error}</StyledError>
    ) : (
      <StyledHelpText>
        &nbsp;
        {helpText}
      </StyledHelpText>
    )}
  </StyledFormGroup>
);

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  helpText: PropTypes.string,
  paddingRight: PropTypes.string,
  errorClassName: PropTypes.string,
};

FormGroup.defaultProps = {
  error: '',
  helpText: '',
  paddingRight: null,
  errorClassName: '',
};

export default FormGroup;
