import * as ActionTypes from './actionTypes';

const initialState = {
  productReferences: null,
  lastFetch: {
    productReferences: new Date(),
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_DATA:
      return {
        ...state,
        ...initialState,
        productReferences: state.productReferences,
        lastFetch: {
          ...state.lastFetch,
          productReferences: state.lastFetch.productReferences,
        },
      };
    case ActionTypes.APP_DATA_PRODUCT_REFERENCES:
      return {
        ...state,
        productReferences: action.payload,
        lastFetch: {
          ...state.lastFetch,
          productReferences: new Date(),
        },
      };
    // case ActionTypes.APP_DATA_PRODUCT_REFERENCES:
    //   return {
    //     ...state,
    //     productReferences: action.payload,
    //     lastFetch: {
    //       ...state.lastFetch,
    //       productReferences: new Date(),
    //     },
    //   };
    // case ActionTypes.APP_DATA_PRODUCT_REFERENCES:
    //   return {
    //     ...state,
    //     productReferences: action.payload,
    //     lastFetch: {
    //       ...state.lastFetch,
    //       productReferences: new Date(),
    //     },
    //   };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
