var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * ------------------------------------------------------------------
 *                              ADMIN
 * ------------------------------------------------------------------
 */
import { useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import entitiesMiddleware from './middlewares';
import rootReducer from './reducers';
import migrations from './migrations';
var persistConfig = {
    key: 'admin-root',
    storage: storage,
    migrate: createMigrate(migrations, { debug: false }),
};
var persistedReducer = persistReducer(persistConfig, rootReducer);
var middlewares = [ReduxThunk, entitiesMiddleware];
// Enable "Redux DevTools" in chrome and firefox
// @todo remove dev tools hook for prod?
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export var store = createStore(persistedReducer, composeEnhancers(applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false))));
var persistor = persistStore(store);
// Use throughout the app instead of plain `useDispatch` and `useSelector` from 'react-redux';
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
export default { store: store, persistor: persistor };
