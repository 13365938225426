import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useAppSelector } from '../../../../core/configStore';
import { selectCurrentUser } from '../../../auth/selectors';
var useDatadog = function () {
    var user = useAppSelector(selectCurrentUser);
    var datadogRumEnabled = process.env.DATADOG_RUM_ENABLED === 'true';
    React.useEffect(function () {
        var _a;
        if (!!(user === null || user === void 0 ? void 0 : user.id) && datadogRumEnabled) {
            datadogRum.init({
                applicationId: process.env.DATADOG_APPLICATION_ID || '',
                clientToken: process.env.DATADOG_CLIENT_TOKEN || '',
                site: 'datadoghq.eu',
                service: process.env.DATADOG_SERVICE,
                env: process.env.DATADOG_ENV,
                sampleRate: Number(process.env.DATADOG_SAMPLING_RATE),
                trackInteractions: true,
                defaultPrivacyLevel: 'allow',
                silentMultipleInit: true,
            });
            datadogRum.setUser({
                id: (_a = user.id) === null || _a === void 0 ? void 0 : _a.toString(),
                name: "".concat(user.firstname, " ").concat(user.lastname),
                email: user.email,
            });
            datadogRum.startSessionReplayRecording();
        }
    }, [user === null || user === void 0 ? void 0 : user.id, user === null || user === void 0 ? void 0 : user.firstname, user === null || user === void 0 ? void 0 : user.lastname, user === null || user === void 0 ? void 0 : user.email, datadogRumEnabled]);
};
export default useDatadog;
