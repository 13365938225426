import * as actionTypes from './actionTypes';

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  user: {
    id: null,
    name: null,
    email: null,
    createdAt: null,
    updatedAt: null,
    onboardingStatus: 1,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_CHECK:
      return { ...state, isAuthenticated: action.payload };
    case actionTypes.AUTH_LOGIN:
      return { ...state, user: action.payload, isAuthenticated: true };
    case actionTypes.AUTH_LOGOUT:
      return { ...state, user: initialState.user, isAuthenticated: false };
    case actionTypes.AUTH_UPDATE_USER:
      return { ...state, user: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
