import { storeToken, removeToken } from 'core/auth';
import http from 'core/http';
import i18n from 'core/i18n';
import * as ActionTypes from './actionTypes';

/**
 * Endpoints
 */
const auth = {
  login: '/api/v1/auth/login',
  logout: '/api/v1/auth/logout',
  me: '/api/v1/me',
};

/**
 * Check if a user is authenticated
 *
 * We check if the localStorage has a token if it does we consider user authenticated
 * If token is expired he will get redirected to /login
 *
 */
export const authCheck = () => {
  const isAuthenticated = !!localStorage.getItem('token');
  return {
    type: ActionTypes.AUTH_CHECK,
    payload: isAuthenticated,
  };
};

/**
 * Login the user
 *
 * @param {*} credentials Email and password
 *
 */
export const authLogin = (credentials) => (dispatch) => {
  removeToken();
  dispatch({ type: ActionTypes.AUTH_LOGOUT });
  dispatch({ type: 'RESET' });

  return new Promise((resolve, reject) => http
    .post(auth.login, credentials)
    .then((res) => {
      const {
        data: { user, accessToken, expiresAt },
      } = res;
      storeToken(accessToken, expiresAt);
      dispatch({
        type: ActionTypes.AUTH_LOGIN,
        payload: user,
      });
      resolve();
    })
    .catch(({ response }) => {
      reject(response.data.message);
    }));
};

/**
 * Logout a user
 *
 * Will delete the token in the local storage and empty the store.
 *
 */
export const authLogout = () => (dispatch) => new Promise((resolve, reject) => http
  .post(auth.logout)
  .then(() => {
    removeToken();
    dispatch({ type: ActionTypes.AUTH_LOGOUT });
    dispatch({ type: 'RESET' });
    resolve();
  }) // @TODO better error handling
  .catch(({ response }) => {
    if (response.status === 500) {
      removeToken();
      dispatch({ type: ActionTypes.AUTH_LOGOUT });
      dispatch({ type: 'RESET' });
      resolve();
    }
    reject();

    return {
      error: [401, 422].includes(response.status) ? response.data.message : '',
      statusCode: response.status,
    };
  }));

/**
 * Get the authenticated user object
 *
 * Used to refresh user info when needed (e.g: during onboarding)
 */
export const fetchCurrentUser = (lang = 'en') => (dispatch) => new Promise((resolve, reject) => http
  .get(auth.me, { params: { lang } })
  .then((res) => {
    dispatch({
      type: ActionTypes.AUTH_LOGIN,
      payload: res.data,
    });
    // Sync language locale
    resolve();
  })
  .catch((err) => {
    if (err.statusCode && err.statusCode === 401) {
      dispatch(authLogout());
    }

    reject(err);
  }));

export const registerUser = (user) => () => {
  if (!!user && !!user.id) {
    i18n.changeLanguage(user.language);
  }
};
