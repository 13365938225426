import * as ActionTypes from './actionTypes';

const initialState = {
  sales: [],
  invoices: [],
  filterOpen: false,
  filter: {
    dateFrom: null,
    dateTo: null,
    orderNumber: null,
    supplier: null,
    customer: null,
    dispatchCountry: null,
    destinationCountry: null,
    volumeMin: null,
    volumeMax: null,
    invoiceNumber: null,
    speciesId: null,
    typeId: null,
    variationId: null,
    gradeId: null,
    fishingMethodId: null,
    harvestCountry: null,
    certificate: null,
    isVariableWeight: null,
    batchNumber: null,
  },
  invoiceFilters: {
    customer: null,
    deliveryDate: null,
    status: null,
  },
  varWeightOfferIds: [],
  varWeightSaleIds: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SALES_ADD:
      return { ...state, sales: [...new Set(state.sales.concat(payload))] };
    case ActionTypes.SALES_SET:
      return { ...state, sales: payload };
    case ActionTypes.SALES_UPDATE:
      return { ...state, sales: [...new Set(state.sales.concat(payload))] };
    case ActionTypes.SALES_DELETE:
      return { ...state, sales: state.sales.filter((s) => s !== payload) };
    case ActionTypes.RESET_DATA:
      return { ...state, ...initialState };
    case ActionTypes.SALES_SET_FILTER:
      return { ...state, filter: { ...state.filter, ...payload } };
    case ActionTypes.SALES_TOGGLE_FILTER:
      return { ...state, filterOpen: !state.filterOpen };
    case ActionTypes.SALES_CLEAR_FILTER:
      return { ...state, filter: initialState.filter };
    case ActionTypes.VARIABLE_WEIGHTS_SET:
      return {
        ...state,
        varWeightOfferIds: payload.offerIds,
        varWeightSaleIds: payload.saleIds,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
