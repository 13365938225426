import React from 'react';
import { Flex, Div, Heading } from '@rooser/boop-react';
import { StyledLoader } from '../AdminApp/styled';
import rooserLogo from 'images/svg/logo-animated.svg';

const PageLoading = () => {
  return (
    <StyledLoader>
      <Flex column horizontal="center" vertical="center" fullHeight>
        <Div textAlign="center">
          <img src={rooserLogo} width="200" height="200" />
          <Heading type="1" margin="mt4">
            Simplifying fish trading...
          </Heading>
        </Div>
      </Flex>
    </StyledLoader>
  );
};

export default PageLoading;
