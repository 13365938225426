import * as ActionTypes from './actionTypes';

const INITIAL_STATE = {
  invoices: [],
  typesOfChange: [],
  reasonsOfChange: [],
  invoiceFilters: {
    customer: null,
    deliveryDate: null,
    status: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.INVOICES_SET_INVOICES:
      return { ...state, invoices: payload };
    case ActionTypes.INVOICES_FETCH_INVOICE:
      return {
        ...state,
        invoices: [...new Set(state.invoices.concat(payload))],
      };
    case ActionTypes.INVOICES_SET_FILTER:
      return { ...state, invoiceFilters: payload };
    case ActionTypes.INVOICES_CLEAR_FILTER:
      return { ...state, invoiceFilters: INITIAL_STATE.invoiceFilters };
    case ActionTypes.INVOICES_DELETE_INVOICE:
      return { ...state, invoices: state.invoices.filter((i) => i !== payload) };
    case ActionTypes.INVOICES_SET_TYPES_OF_CHANGE:
      return { ...state, typesOfChange: payload };
    case ActionTypes.INVOICES_SET_REASONS_OF_CHANGE:
      return { ...state, reasonsOfChange: payload };
    case 'RESET':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
