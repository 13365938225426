import styled from 'styled-components';

export const StyledFormGroup = styled.div`
  padding-bottom: 18px;
  padding-right: ${({ paddingRight }) => paddingRight || 0};
`;

export const StyledError = styled.div`
  color: #cb0000;
  font-size: 0.75rem;
  position: absolute;
`;

export const StyledHelpText = styled.div`
  color: #999;
  font-size: 0.75rem;
  position: absolute;
`;
