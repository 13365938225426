/**
 * Check  if the actions carry entities and update the targeted reducers if it does
 * @param {*} state
 */
const entitiesMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.deleteId && action.entityName) {
    // @TODO test this
    dispatch({
      type: 'ENTITIES_DELETE',
      payload: { entityId: action.deleteId, entityName: action.entityName },
    });
  }
  if (action.entities) {
    dispatch({
      type: 'ENTITIES_SET',
      payload: action.entities,
    });
  }
  next(action);
};

export default entitiesMiddleware;
