import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import * as Sentry from '@sentry/react';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { datadogRum } from '@datadog/browser-rum';

import './core/i18n';
// import register from './core/serviceWorker';
import configStore, { store } from './core/configStore.ts';
import { authCheck } from './features/auth/actions';

import AdminRoot from './AdminRoot';
import cssVariables from './scss/variables.scss';
import './scss/style.scss';

const queryCache = new QueryCache();

const history = createBrowserHistory();

// register();

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  ignoreErrors: [
    'Unexpected input given to normalize.',
    "Cannot read property 'data' of undefined",
  ],
  integrations: [
    new SentryTracingIntegrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  maxBreadcrumbs: 50,
  release: `${process.env.SENTRY_PROJECT}@${process.env.AWS_JOB_ID}`, // TODO: Change this to a value set by CI pipeline.
  tracesSampleRate: Number(process.env.SENTRY_SAMPLING_RATE),
});

store.dispatch(authCheck());

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={configStore.persistor}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <ThemeProvider theme={cssVariables}>
          <AdminRoot history={history} />
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </ThemeProvider>
      </ReactQueryCacheProvider>
    </PersistGate>
  </Provider>
);

render(<Root />, document.getElementById('react-root'));

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept();
}
// Expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
