import React from 'react';
import { func } from 'prop-types';
import { Flex, Div, Button } from '@rooser/boop-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { feedbackLink } from 'core/const';
import deadFish from 'images/svg/dead-fish.svg';

const ContentBoundary = ({ t }) => (
  <Flex horizontal="center" vertical="middle" fullHeight>
    <Div textAlign="center" padding="px5">
      <img
        width="300"
        src={deadFish}
        alt={t('app:contentBoundary,header', 'Oops! Something went wrong')}
      />
      <h1>{t('app:contentBoundary,header', 'Oops! Something went wrong')}</h1>
      <p>
        <br />
        {t(
          'app:contentBoundary,error',
          'We are experiencing a technical issue.',
        )}
        <br />
        {t('app:contentBoundary,solution', "Don't panic, the team is on it")}
      </p>
      <p>
        <Link to="/dashboard">
          <Button>
            {t('app:contentBoundary,actionBack', 'Go back to dashboard')}
          </Button>
        </Link>
      </p>
      <p>
        <a href={feedbackLink} target="_blank" rel="noopener noreferrer">
          <Button>
            {t('app:contentBoundary,actionReport', 'Report this error')}
          </Button>
        </a>
      </p>
    </Div>
  </Flex>
);

ContentBoundary.propTypes = {
  t: func.isRequired,
};

export default withTranslation('app')(ContentBoundary);
