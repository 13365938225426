var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as ActionTypes from './actionTypes';
export var initialState = {
    creditNotes: [],
    filters: {
        invoiceNumber: null,
        createdDate: null,
        status: null,
        customer: null,
    },
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.CREDIT_NOTES_SET:
            return __assign(__assign({}, state), { creditNotes: action.payload });
        case ActionTypes.CREDIT_NOTES_ADD:
            return __assign(__assign({}, state), { creditNotes: __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(state.creditNotes), false), [action.payload], false))), false) });
        case ActionTypes.CREDIT_NOTES_FILTERS_SET:
            return __assign(__assign({}, state), { filters: action.payload });
        case ActionTypes.CREDIT_NOTES_FILTERS_RESET:
            return __assign(__assign({}, state), { filters: initialState.filters });
        case 'RESET':
            return initialState;
        default:
            return state;
    }
});
