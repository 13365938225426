import * as ActionTypes from './actionTypes';

const initialState = {
  currentCompany: null,
  users: [],
  addresses: [],
  companies: [],
  dispatchAddresses: [],
  billingAddress: [],
  filter: {
    customers: null,
    suppliers: null,
  },
  lastFetch: {
    users: new Date(),
    addresses: new Date(),
    companies: new Date(),
  },
  destinations: [],
  onlineUsers: [],
  linkedBuyers: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.COMPANIES_USERS:
      return {
        ...state,
        users: payload,
        lastFetch: {
          ...state.lastFetch,
          users: new Date(),
        },
      };
    case ActionTypes.COMPANIES_USERS_ADD:
      return {
        ...state,
        users: [...new Set(state.users.concat(payload))],
      };
    case ActionTypes.COMPANIES_SET:
      return {
        ...state,
        companies: payload,
        lastFetch: {
          ...state.lastFetch,
          companies: new Date(),
        },
      };
    case ActionTypes.COMPANIES_TYPE_ADD:
      return {
        ...state,
        companies: [...new Set(state.companies.concat(...payload))],
      };
    case ActionTypes.COMPANIES_ADD:
      return {
        ...state,
        companies: [...new Set(state.companies.concat(payload))],
      };
    case ActionTypes.COMPANIES_ADDRESSES:
      return {
        ...state,
        addresses: payload,
        lastFetch: {
          ...state.lastFetch,
          addresses: new Date(),
        },
      };
    case ActionTypes.COMPANIES_BILLING_ADDRESS:
      return { ...state, billingAddress: action.payload };
    case ActionTypes.COMPANIES_TOGGLE_USER:
      return {
        ...state,
        users: [...new Set(state.users.concat(payload))],
      };
    case ActionTypes.COMPANIES_SET_CURRENT:
      return {
        ...state,
        currentCompany: payload,
      };
    case ActionTypes.COMPANIES_SET_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.COMPANIES_CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      };
    case ActionTypes.COMPANIES_DESTINATION:
      return {
        ...state,
        destinations: [...new Set(state.destinations.concat(payload))],
      };
    case ActionTypes.COMPANIES_DESTINATION_DELETE:
      return {
        ...state,
        destinations: state.destinations.filter((destId) => destId !== payload),
      };
    case ActionTypes.COMPANIES_DISPATCH:
      return {
        ...state,
        dispatchAddresses: payload,
      };
    case ActionTypes.COMPANIES_DISPATCH_ADD:
      return {
        ...state,
        dispatchAddresses: [...new Set(state.dispatchAddresses.concat(payload))],
      };
    case ActionTypes.COMPANIES_DISPATCH_DELETE:
      return {
        ...state,
        dispatchAddresses: state.dispatchAddresses.filter((id) => id !== payload),
      };
    case ActionTypes.COMPANIES_UPDATE_ONLINE_USERS:
      return { ...state, onlineUsers: payload };
    case ActionTypes.COMPANIES_SET_LINKED_BUYERS:
      return {
        ...state,
        linkedBuyers: payload,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
