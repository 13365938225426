export const MARKETPLACE_ORDERS_SET_SELECTED_DAY = 'Marketplace: set selected day in orders table';
export const MARKETPLACE_ORDERS_FETCH_CUSTOMER_SALES = 'Marketplace: fetch customer orders';
export const MARKETPLACE_ORDER_ADD = 'Market: add new sale';
export const MARKETPLACE_ORDER_REMOVE = 'Marketplace: Removes an order from the store';
export const MARKETPLACE_COMPANIES = 'Marketplace: set all companies list';
export const MARKETPLACE_OFFERS = 'Marketplace: set offers visible for selected customer';
export const MARKETPLACE_OFFERS_RESET = 'Marketplace: reset offers';
export const MARKETPLACE_ROUTES = 'Marketplace: set routes list';
export const MARKETPLACE_SET_FILTERS = 'Marketplace: set backoffice marketplace filters';
export const MARKETPLACE_RESET_FILTERS = 'Marketplace: reset back office filters';
export const MARKETPLACE_SELECTED_CUSTOMER = 'Marketplace: set selected customer';
export const MARKETPLACE_OFFERS_ADD = 'Market: add offer to the list';
export const MARKETPLACE_OFFERS_REMOVE = 'Market: remove offer (goes offline, sold or canceled)';
export const MARKETPLACE_UPDATE_SHOW_EXPIRED_OFFERS = 'Marketplace: toggle on/off showing expired offers';
export const MARKETPLACE_OFFERS_FETCH_ONE = 'Market: fetch an individual offer with updated resource';
export const MARKETPLACE_ADMINS_SET = 'Market: set admins';
export const MARKETPLACE_FAVOURITES_SET = 'Market: set favourites from the selected buyer';
export const MARKETPLACE_FAVOURITES_ONLY = 'Market: toggle on/off favourites offers only';
export const MARKETPLACE_MUST_GO_ONLY = 'Market: toggle on/off must go offers only';
export const MARKETPLACE_OPPORTUNITY_ONLY = 'Market: toggle on/off opportunity offers only';

// CURATED OFFERS
export const MARKETPLACE_CREATE_CURATED_OFFERS = 'Market: create a curated offers list';
export const MARKETPLACE_RESET_CURATED_OFFERS = 'Market: reset the curated offers list';
export const MARKETPLACE_UPDATE_CURATED_OFFERS = 'Market: add or remove offer id from day offers';
export const MARKETPLACE_CREATE_WHATSAPP_OFFER_LIST = 'Market: open whatsapp with formatted text';

// OFFERS
export const MARKETPLACE_UPDATE_OFFER = 'Market: update existing offer';
