export const SALES_SET = 'Sales: set sales';
export const SALES_ADD = 'Sales: add a single sale';
export const SALES_UPDATE = 'Sales: update a sales';
export const SALES_DELETE = 'Sales: delete sale';
export const SALES_SET_FILTER = 'Sales: set filter';
export const SALES_TOGGLE_FILTER = 'Sales: toggle filter';
export const SALES_CLEAR_FILTER = 'Sales: clear filter';
export const RESET_DATA = 'Reset data';

export const FXCONTRACTS_FETCH = 'Fx Contracts: fetch forex contracts from rooser api';
export const FXCONTRACTS_ADD_NEW = 'Fx Contracts: add a new forex contract';

export const VARIABLE_WEIGHTS_SET = 'Variable Weights: fetch Sales with pending variable weights from rooser api';
export const VARIABLE_WEIGHTS_SUBMIT = 'Variable Weights: submit variable weights of a list of sales from the same offer';
export const VARIABLE_WEIGHTS_SAVE_WORK = 'VariableWeights: save variable weights form values of a given group of sales of the same offer';
export const VARIABLE_WEIGHTS_DELETE_OFFER_SAVED_WORK = 'VariableWeights: delete saved work from the store after successfully submitting variable weights for an offer';
