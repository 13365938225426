import dayjs from 'dayjs';
import * as ActionTypes from './actionTypes';

export const initialState = {
  companies: [],
  offers: [],
  routes: [],
  selectedCustomer: null,
  favourites: [],
  filter: {
    species: [],
    type: [],
    variation: [],
    grade: [],
  },
  admins: [],
  // Below Merged values @TODO sync your store structure
  isFilterOpen: true,
  openedOrders: [],
  orders: [],
  latestDay: dayjs().add(5, 'day').format('YYYY-MM-DD'),
  selectedDay: dayjs().format('YYYY-MM-DD'),
  showExpiredOffers: false,
  favouritesOnly: false,
  mustGoOnly: false,
  opportunityOnly: false,
  curatedOffers: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Sales
    case ActionTypes.MARKETPLACE_ORDERS_SET_SELECTED_DAY:
      return { ...state, selectedDay: payload };
    case ActionTypes.MARKETPLACE_ORDERS_FETCH_CUSTOMER_SALES:
      return { ...state, orders: payload };
    case ActionTypes.MARKETPLACE_ORDER_ADD:
      return { ...state, orders: [...new Set(state.orders.concat(payload))] };
    case ActionTypes.MARKETPLACE_ORDER_REMOVE:
      return { ...state, orders: state.orders.filter((id) => id !== payload) };
    case ActionTypes.MARKETPLACE_COMPANIES:
      return { ...state, companies: action.payload };
    // Offers
    case ActionTypes.MARKETPLACE_OFFERS:
      return { ...state, offers: payload };
    case ActionTypes.MARKETPLACE_RESET_OFFERS:
      return { ...state, offers: [] };
    case ActionTypes.MARKETPLACE_OFFERS_ADD:
      return { ...state, offers: [...new Set(state.offers.concat(payload))] };
    case ActionTypes.MARKETPLACE_OFFERS_REMOVE:
      return { ...state, offers: state.offers.filter((o) => o !== payload) };
    case ActionTypes.MARKETPLACE_OFFERS_RESET:
      return { ...state, offers: [] };
    case ActionTypes.MARKETPLACE_ROUTES:
      return { ...state, routes: payload };
    case ActionTypes.MARKETPLACE_SET_FILTERS:
      return { ...state, filter: { ...state.filter, ...payload } };
    case ActionTypes.MARKETPLACE_RESET_FILTERS:
      return {
        ...state,
        filter: initialState.filter,
        favouritesOnly: false,
        mustGoOnly: false,
        opportunityOnly: false,
      };
    case ActionTypes.MARKETPLACE_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: payload };
    case ActionTypes.MARKETPLACE_UPDATE_SHOW_EXPIRED_OFFERS:
      return { ...state, showExpiredOffers: !state.showExpiredOffers };
    case ActionTypes.MARKETPLACE_UPDATE_CURATED_OFFERS:
      return {
        ...state,
        curatedOffers: state.curatedOffers.includes(payload)
          ? state.curatedOffers.filter((id) => id !== payload)
          : state.curatedOffers.concat(payload),
      };
    case ActionTypes.MARKETPLACE_RESET_CURATED_OFFERS:
      return { ...state, curatedOffers: [] };
    case ActionTypes.MARKETPLACE_ADMINS_SET:
      return {
        ...state,
        admins: payload,
      };
    case ActionTypes.MARKETPLACE_FAVOURITES_ONLY:
      return { ...state, favouritesOnly: !state.favouritesOnly };
    case ActionTypes.MARKETPLACE_MUST_GO_ONLY:
      return { ...state, mustGoOnly: !state.mustGoOnly };
    case ActionTypes.MARKETPLACE_OPPORTUNITY_ONLY:
      return { ...state, opportunityOnly: !state.opportunityOnly };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
