exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"white": "#fff",
	"greyLighter": "#f9f9f9",
	"greyLight": "#f4f4f7",
	"greyLightblue": "#f0f0f7",
	"greyMedium": "#f0f0f7",
	"greyDark": "#404040",
	"greyBorder": "#b4b4b4",
	"orangeLight": "#f5831f",
	"orange": "#f89925",
	"orangeDark": "#f26a21",
	"blueLight": "#0883d9",
	"blue": "#0b72ba",
	"blueDark": "#0968aa"
};