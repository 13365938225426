export default {
  0: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      curatedOffers: [],
    },
  }),
  1: (state) => {
    const logisticInfoState = state.logisticInfo;
    delete logisticInfoState.logisticInfos;

    return {
      ...state,
      logisticInfo: {
        ...logisticInfoState,
        logisticInfo: [],
      },
    };
  },
  2: (state) => ({
    ...state,
    creditNotes: {
      creditNotes: [],
    },
  }),
  3: (state) => ({
    ...state,
    creditNotes: {
      ...state.creditNotes,
      filters: {
        invoiceNumber: null,
        createdDate: null,
        status: null,
        customer: null,
      },
    },
  }),
};
