import * as ActionTypes from './actionTypes';

const initialState = {
  showModal: false,
  modalType: null,
  modalProps: {},
  confirmOnClose: false,
  showToast: false,
  toastType: 'info',
  toastTitle: 'Hello',
  toastMessage: 'Welcome to Rooser',
  toastClickAction: null,
  toastPayload: null,
  toastPosition: 'top-right',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        confirmOnClose: false,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case ActionTypes.CLOSE_MODAL:
      return { ...state, showModal: false, modalType: null, confirmOnClose: false };
    case ActionTypes.CONFIRM_CLOSE_MODAL:
      return { ...state, confirmOnClose: action.payload };
    case ActionTypes.SHOW_TOAST:
      return { ...state, showToast: true };
    case ActionTypes.HIDE_TOAST:
      return {
        ...state,
        showToast: false,
        toastTitle: '',
        toastMessage: '',
        toastType: 'info',
        toastClickAction: null,
        toastPayload: null,
        toastPosition: 'top-right',
      };
    case ActionTypes.SET_TOAST:
      return { ...state, ...action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
