/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
import { lazy } from 'react';

export const lazyLoadWithRetry = (componentImportCallback, componentName) => lazy(
  () => new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${componentName}-refreshed`) || 'false',
    );

    componentImportCallback()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(`retry-${componentName}-refreshed`, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  }),
);

export default lazyLoadWithRetry;
