import i18n from './i18n';

/**
 * Constants
 *
 * Note: many reflects what in App\Enums classes in Laravel
 */
export const mininumKgPerOrder = 1;

export const languages = [
  { locale: 'en', text: 'English' },
  { locale: 'es', text: 'Español' },
  { locale: 'fr', text: 'Français' },
];

export const Rooser = {
  comissionPercentage: 3,
  bankFeesPercentage: 0.5,
  insurancePercentage: 0.2,
  // Fishing method Exception
  farming: [9, 10, 11, 12],
};

export const getTransportCost = (totalKg, route) => {
  let value = null;
  if (route) {
    if (totalKg >= 50 && totalKg <= 100) {
      value = route.totalLevel1;
    }
    if (totalKg > 100 && totalKg <= 300) {
      value = route.totalLevel2;
    }
    if (totalKg > 300 && totalKg <= 500) {
      value = route.totalLevel3;
    }
    if (totalKg > 500) {
      value = route.totalLevel4;
    }
  }

  return value;
};

export const getPriceLevel = (totalKg) => {
  if (totalKg >= 50 && totalKg <= 100) {
    return '50-100 kg';
  }
  if (totalKg > 100 && totalKg <= 300) {
    return '100-300 kg';
  }
  if (totalKg > 300 && totalKg <= 500) {
    return '300-500 kg';
  }
  if (totalKg > 500) {
    return '500+ kg';
  }
  return '50-100kg';
};

/**
 *  -----------------------------------------------------------------
 *          Offers/Market constants
 *  -----------------------------------------------------------------
 */

export const qualityRatings = [
  { value: 1, text: 'Extra', shortText: 'E' },
  { value: 2, text: 'A+', shortText: 'A+' },
  { value: 3, text: 'A', shortText: 'A' },
  { value: 4, text: 'A-', shortText: 'A-' },
  { value: 5, text: 'B', shortText: 'B' },
];

export const qualityRating = (qualityValue) => qualityRatings.find(({ value }) => qualityValue === value);

/**
 *  -----------------------------------------------------------------
 *          ONBOARDING constants
 *  -----------------------------------------------------------------
 */

export const onboardingStatuses = {
  signUp: 1,
  verification: 2,
  printTest: 3,
  initialOnboarding: 4,
  fullOnboarding: 5,
};

/**
 *  -----------------------------------------------------------------
 *          ADMINISTRATION constants
 *  -----------------------------------------------------------------
 */
export const companyType = {
  supplier: 0,
  buyer: 1,
  transport: 2,
  rooser: 9,
};

export const addressTypes = [
  { label: 'Headquarter', value: 0, color: '' },
  { label: 'Billing', value: 1, color: '' },
  { label: 'Dispatch', value: 2, color: 'orange' },
  { label: 'Delivery', value: 3, color: '#16a085' },
  { label: 'Platform', value: 4, color: '#8e44ad' },
  { label: 'Preparation', value: 5, color: '#5A2B6E' },
];

export const invoiceSettings = [
  { label: 'Rooser Limited (GB)', value: 0 },
  { label: 'Rooser SAS (FR)', value: 1 },
];

/**
 * Rooser details
 *
 * Last update: 8th January 2021
 */

export const rooserOffices = {
  0: {
    rooser: true,
    name: 'ROOSER Limited',
    line1: '525 Ferry Road',
    postcode: 'EH5 2FF',
    townCity: 'Edinburgh',
    country: 'SCO',
    vatNumber: '335094114',
    vatRate: 0,
    registrationNumber: 'SC639014',
    countryFull: 'United Kingdom',
    capitalSocial: '',
  },
  1: {
    rooser: true,
    name: 'ROOSER SAS',
    line1: '13 Rue du Clos Matignod',
    postcode: '35400',
    townCity: 'St-Malo',
    country: 'FRA',
    vatNumber: 'FR 84 878422906',
    vatRate: 0.055, // % as decimal between 0 and 1
    registrationNumber: 'SAINT-MALO 878.422.906',
    countryFull: 'France',
    capitalSocial: 'Capital social 20 250 EUR',
  },
};
/**
 * Rooser bank details
 *
 * Last update: 25th October 2023
 */
export const rooserBanks = {
  0: {
    name: 'Revolut',
    accountNumber: '20061609',
    sortCode: '04-00-75',
    iban: 'GB57 REVO 0099 6935 7349 01',
    bicSwift: 'REVOGB21',
  },
  1: {
    name: 'Revolut',
    accountNumber: '',
    sortCode: '',
    iban: 'FR7628233000015177441508665',
    bicSwift: 'REVOFRP2',
  },
};

/**
 * Rooser payment details
 *
 * Last update: 19th July 2019
 */
export const rooserPaymentTerms = {
  days: 30, // Invoice Payment terms
};

export const invoiceStatus = {
  Open: 0,
  Consolidated: 1,
  Pending: 2,
  Paid: 3,
};

export const invoiceStatuses = [
  { value: 0, text: i18n.t('generic:invoiceStatuses.open', 'Open') },
  {
    value: 1,
    text: i18n.t('generic:invoiceStatuses.consolidated', 'Consolidated'),
  },
  { value: 2, text: i18n.t('generic:invoiceStatuses.pending', 'Pending') },
  { value: 3, text: i18n.t('generic:invoiceStatuses.paid', 'Paid') },
];

export const invoiceDocumentTypes = [
  { value: null, text: i18n.t('sales:invoice.allStatus', 'All') },
  { value: 0, text: i18n.t('sales:deliveryNote', 'Delivery note') },
  { value: 1, text: i18n.t('sales:invoice.invoice', 'Invoice') },
];

export const addressType = {
  headquarter: 0,
  billing: 1,
  dispatch: 2,
  delivery: 3,
  platform: 4,
  preparation: 5,
};

export const unitType = {
  gram: 1,
  kilogram: 2,
  stone: 3,
  pound: 4,
  piece: 5,
  piecePerKilogram: 6,
  pot: 7,
};

export const unitTypes = [
  { text: 'gram', symbol: 'g', value: 1 },
  { text: 'kilogram', symbol: 'kg', value: 2 },
  { text: 'stone', symbol: 'st', value: 3 },
  { text: 'pound', symbol: 'lb', value: 4 },
  { text: 'piece', symbol: 'pc', value: 5 },
  { text: 'piece per kg', symbol: 'pc/kg', value: 6 },
  { text: 'pot', symbol: 'pot', value: 7 },
];

export const occupationType = [
  { text: 'Director', value: 1 },
  { text: 'Manager', value: 2 },
  { text: 'Accountant', value: 3 },
  { text: 'Buyer/Seller', value: 4 },
  { text: 'Admin', value: 5 },
];

export const volumeIntervals = [
  { label: '30-100Kg', min: 30, max: 100 },
  { label: '100-300Kg', min: 100, max: 300 },
  { label: '300-500Kg', min: 300, max: 500 },
  { label: '500+ Kg', min: 500, max: 99999 },
];

export const deliveryTypes = {
  aForA: 0,
  aForB: 1,
  aForC: 2,
  aForD: 3,
};

export const deliveryTypeOptions = [
  { days: 0, text: 'Day A for A' },
  { days: 1, text: 'Day A for B' },
  { days: 2, text: 'Day A for C' },
  { days: 3, text: 'Day A for D' },
];

export const routeType = [
  {
    label: 'Platform',
    type: 0,
    addressType: 4,
    color: '#8e44ad',
    stroke: 'solid',
  },
  {
    label: 'Delivery',
    type: 1,
    addressType: 3,
    color: '#16a085',
    stroke: 'dashed',
  },
  {
    label: 'Dispatch',
    type: 2,
    addressType: 2,
    color: 'orange',
    stroke: 'dotted',
  },
  {
    label: 'Preparation',
    type: 3,
    addressType: 5,
    color: '#5A2B6E',
    stroke: 'solid',
  },
];

export const routeTypes = {
  platform: 0,
  delivery: 1,
  dispatch: 2,
  preparation: 3,
};

export const currencies = [
  {
    code: 'EUR',
    label: 'Euro',
    symbol: '€',
  },
  {
    code: 'GBP',
    label: 'British Pound Sterling',
    symbol: '£',
  },
  {
    code: 'BGN',
    label: 'Bulgarian Lev',
    symbol: 'Лв',
  },
  {
    code: 'HRK',
    label: 'Croatian Kuna',
    symbol: 'kn',
  },
  {
    code: 'CZK',
    label: 'Czech Koruna',
    symbol: 'Kč',
  },
  {
    code: 'DKK',
    label: 'Danish Krone',
    symbol: 'Kr.',
  },
  {
    code: 'HUF',
    label: 'Hungarian Forint',
    symbol: 'Ft',
  },
  {
    code: 'ISK',
    label: 'Icelandic Króna',
    symbol: 'Íkr',
  },
  {
    code: 'PLN',
    label: 'Polish Zloty',
    symbol: 'zł',
  },
  {
    code: 'RON',
    label: 'Romanian Leu',
    symbol: 'lei',
  },
  {
    code: 'SEK',
    label: 'Swedish Krona',
    symbol: 'kr',
  },
  {
    code: 'CHF',
    label: 'Swiss Franc',
    symbol: 'CHf',
  },
  {
    code: 'USD',
    label: 'United States Dollar',
    symbol: '$',
  },
];

export const europeanCurrencies = {
  EUR: 'Euro',
  GBP: 'British Pound Sterling',
  BGN: 'Bulgarian Lev',
  CHF: 'Swiss Franc',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  HRK: 'Croatian Kuna',
  HUF: 'Hungarian Forint',
  ISK: 'Icelandic Króna',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  SEK: 'Swedish Krona',
  // USD: 'United States Dollar',
};

export const currencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  BGN: 'Лв',
  CHF: 'CHf',
  CZK: 'Kč',
  DKK: 'Kr.',
  HRK: 'kn',
  HUF: 'Ft',
  ISK: 'Íkr',
  RON: 'lei',
  SEK: 'kr',
};

/**
 * This list needs to be synced with FishingArea.php list
 *
 * @see App\Enum\FishingArea.php
 */
export const originCountries = [
  {
    country: 'Albania',
    code: 'ALB',
    iso: 'AL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇦🇱',
    union: false,
  },
  {
    country: 'Austria',
    code: 'AUT',
    iso: 'AT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇦🇹',
    union: false,
  },
  {
    country: 'Bangladesh',
    code: 'BGD',
    iso: 'BD',
    timezone: 'Asia/Dhaka',
    utc: 6,
    flag: '🇧🇩',
    union: false,
  },
  {
    country: 'Belgium',
    code: 'BEL',
    iso: 'BE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇧🇪',
    union: false,
  },
  {
    country: 'Bosnia and Herzegovina',
    code: 'BIH',
    iso: 'BA',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇧🇦',
    union: false,
  },
  {
    country: 'Brazil',
    code: 'BRA',
    iso: 'BR',
    timezone: 'America/Bahia',
    utc: -3,
    flag: '🇧🇷',
    union: false,
  },
  {
    country: 'British Virgin Islands',
    code: 'VGB',
    iso: 'GB',
    timezone: 'Atlantic/Bermuda',
    utc: -4,
    flag: '🇻🇬',
    union: false,
  },
  {
    country: 'Bulgaria',
    code: 'BGR',
    iso: 'BG',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇧🇬',
    union: false,
  },
  {
    country: 'Canada',
    code: 'CAN',
    iso: 'CA',
    timezone: 'America/Toronto',
    utc: -5,
    flag: '🇨🇦',
    union: false,
  },
  {
    country: 'Chile',
    code: 'CHL',
    iso: 'CL',
    timezone: 'America/Santiago',
    utc: -3,
    flag: '🇨🇱',
    union: false,
  },
  {
    country: 'Colombia',
    code: 'COL',
    iso: 'CO',
    timezone: 'America/Bogota',
    utc: -5,
    flag: '🇨🇴',
    union: false,
  },
  {
    country: 'Cuba',
    code: 'CUB',
    iso: 'CU',
    timezone: 'America/Havana',
    utc: -5,
    flag: '🇨🇺',
    union: false,
  },
  {
    country: 'Croatia',
    code: 'HRV',
    iso: 'HR',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇭🇷',
    union: false,
  },
  {
    country: 'Cyprus',
    code: 'CYP',
    iso: 'CY',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇨🇾',
    union: false,
  },
  {
    country: 'Czech Republic',
    code: 'CZE',
    iso: 'CZ',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇨🇿',
    union: false,
  },
  {
    country: 'Denmark',
    code: 'DNK',
    iso: 'DK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇩🇰',
    union: false,
  },
  {
    country: 'Ecuador',
    code: 'ECU',
    iso: 'EC',
    timezone: 'America/Guayaquil',
    utc: -5,
    flag: '🇪🇨',
    union: false,
  },
  {
    country: 'England',
    code: 'ENG',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    union: true,
  },
  {
    country: 'Estonia',
    code: 'EST',
    iso: 'EE',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇪🇪',
    union: false,
  },
  {
    country: 'Faroe Islands',
    code: 'FRO',
    iso: 'FO',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇫🇴',
    union: false,
  },
  {
    country: 'Finland',
    code: 'FIN',
    iso: 'FI',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇫🇮',
    union: false,
  },
  {
    country: 'France',
    code: 'FRA',
    iso: 'FR',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇫🇷',
    union: false,
  },
  {
    country: 'Germany',
    code: 'DEU',
    iso: 'DE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇩🇪',
    union: false,
  },
  {
    country: 'Gibraltar',
    code: 'GIB',
    iso: 'GI',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇬🇮',
    union: false,
  },
  {
    country: 'Greece',
    code: 'GRC',
    iso: 'GR',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇬🇷',
    union: false,
  },
  {
    country: 'Hungary',
    code: 'HUN',
    iso: 'HU',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇭🇺',
    union: false,
  },
  {
    country: 'Iceland',
    code: 'ISL',
    iso: 'IS',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇸',
    union: false,
  },
  {
    country: 'India',
    code: 'IND',
    iso: 'IN',
    timezone: 'Asia/Calcutta',
    utc: 5.5,
    flag: '🇮🇳',
    union: false,
  },
  {
    country: 'Iran',
    code: 'IRN',
    iso: 'IR',
    timezone: 'Asia/Tehran',
    utc: 3.5,
    flag: '🇮🇷',
    union: false,
  },
  {
    country: 'Ireland',
    code: 'IRL',
    iso: 'IE',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇪',
    union: false,
  },
  {
    country: 'Israel',
    code: 'ISR',
    iso: 'IL',
    timezone: 'Asia/Jerusalem',
    utc: 3,
    flag: '🇮🇱',
    union: false,
  },
  {
    country: 'Italy',
    code: 'ITA',
    iso: 'IT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇮🇹',
    union: false,
  },
  {
    country: 'Ivory Coast',
    code: 'CIV',
    iso: 'CI',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇨🇮',
    union: false,
  },
  {
    country: 'Japan',
    code: 'JPN',
    iso: 'JP',
    timezone: 'Asia/Tokyo',
    utc: 9,
    flag: '🇯🇵',
    union: false,
  },
  {
    country: 'Latvia',
    code: 'LVA',
    iso: 'LV',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇱🇻',
    union: false,
  },
  {
    country: 'Lithuania',
    code: 'LTU',
    iso: 'LT',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇱🇹',
    union: false,
  },
  {
    country: 'Luxembourg',
    code: 'LUX',
    iso: 'LU',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇱🇺',
    union: false,
  },
  {
    country: 'Madagascar',
    code: 'MDG',
    iso: 'MG',
    timezone: 'Africa/Nairobi',
    utc: 3,
    flag: '🇲🇬',
    union: false,
  },
  {
    country: 'Maldives',
    code: 'MDV',
    iso: 'MV',
    timezone: 'Indian/Maldives',
    utc: 5,
    flag: '🇲🇻',
    union: false,
  },
  {
    country: 'Malta',
    code: 'MLT',
    iso: 'MT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇹',
    union: false,
  },
  {
    country: 'Mauritania',
    code: 'MRT',
    iso: 'MR',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇲🇷',
    union: false,
  },
  {
    country: 'Mauritius',
    code: 'MUS',
    iso: 'MU',
    timezone: 'Indian/Mauritius',
    utc: 4,
    flag: '🇲🇺',
    union: false,
  },
  {
    country: 'Moldova',
    code: 'MDA',
    iso: 'MD',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇲🇩',
    union: false,
  },
  {
    country: 'Montenegro',
    code: 'MNE',
    iso: 'ME',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇪',
    union: false,
  },
  {
    country: 'Morocco',
    code: 'MAR',
    iso: 'MA',
    timezone: 'Africa/Casablanca',
    utc: 0,
    flag: '🇲🇦',
    union: false,
  },
  {
    country: 'Netherlands',
    code: 'NLD',
    iso: 'NL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇱',
    union: false,
  },
  {
    country: 'Nicaragua',
    code: 'NIC',
    iso: 'NI',
    timezone: 'America/Managua',
    utc: -6,
    flag: '🇳🇮',
    union: false,
  },
  {
    country: 'Nigeria',
    code: 'NGA',
    iso: 'NG',
    timezone: 'Africa/Lagos',
    utc: 1,
    flag: '🇳🇬',
    union: false,
  },
  {
    country: 'North Macedonia',
    code: 'MKD',
    iso: 'MK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇰',
    union: false,
  },
  {
    country: 'Northern Ireland',
    code: 'NIR',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇬🇧',
    union: true,
  },
  {
    country: 'Norway',
    code: 'NOR',
    iso: 'NO',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇴',
    union: false,
  },
  {
    country: 'Peru',
    code: 'PER',
    iso: 'PE',
    timezone: 'America/Lima',
    utc: -5,
    flag: '🇵🇪',
    union: false,
  },
  {
    country: 'Poland',
    code: 'POL',
    iso: 'PL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇵🇱',
    union: false,
  },
  {
    country: 'Portugal',
    code: 'PRT',
    iso: 'PT',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇵🇹',
    union: false,
  },
  {
    country: 'Romania',
    code: 'ROU',
    iso: 'RO',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇷🇴',
    union: false,
  },
  {
    country: 'Scotland',
    code: 'SCO',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    union: true,
  },
  {
    country: 'Senegal',
    code: 'SEN',
    iso: 'SN',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇸🇳',
    union: false,
  },
  {
    country: 'Serbia',
    code: 'SRB',
    iso: 'RS',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇷🇸',
    union: false,
  },
  {
    country: 'Seychelles',
    code: 'SYC',
    iso: 'SC',
    timezone: 'Indian/Mahe',
    utc: 4,
    flag: '🇸🇨',
    union: false,
  },
  {
    country: 'Slovakia',
    code: 'SVK',
    iso: 'SK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇰',
    union: false,
  },
  {
    country: 'Slovenia',
    code: 'SVN',
    iso: 'SI',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇮',
    union: false,
  },
  {
    country: 'South Africa',
    code: 'ZAF',
    iso: 'ZA',
    timezone: 'Africa/Johannesburg',
    utc: 2,
    flag: '🇿🇦',
    union: false,
  },
  {
    country: 'Spain',
    code: 'ESP',
    iso: 'ES',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇪🇸',
    union: false,
  },
  {
    country: 'Sri Lanka',
    code: 'LKA',
    iso: 'LK',
    timezone: 'Indian/Standard',
    utc: 5,
    flag: '🇱🇰',
    union: false,
  },
  {
    country: 'Sweden',
    code: 'SWE',
    iso: 'SE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇪',
    union: false,
  },
  {
    country: 'Switzerland',
    code: 'CHE',
    iso: 'CH',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇨🇭',
    union: false,
  },
  {
    country: 'Thailand',
    code: 'THA',
    iso: 'TH',
    timezone: 'Asia/Bangkok',
    utc: 7,
    flag: '🇹🇭',
    union: false,
  },
  {
    country: 'Tunisia',
    code: 'TUN',
    iso: 'TN',
    timezone: 'Africa/Tunis',
    utc: 1,
    flag: '🇹🇳',
    union: false,
  },
  {
    country: 'Turkey',
    code: 'TUR',
    iso: 'TR',
    timezone: 'Europe/Istanbul',
    utc: 3,
    flag: '🇹🇷',
    union: false,
  },
  {
    country: 'Ukraine',
    code: 'UKR',
    iso: 'UA',
    timezone: 'Europe/Moscow',
    utc: 3,
    flag: '🇺🇦',
    union: false,
  },
  {
    country: 'United States of America',
    code: 'USA',
    iso: 'US',
    timezone: 'America/New_York',
    utc: -5,
    flag: '🇺🇸',
    union: false,
  },
  {
    country: 'Venezuela',
    code: 'VEN',
    iso: 'VE',
    timezone: 'America/Caracas',
    utc: -4,
    flag: '🇻🇪',
    union: false,
  },
  {
    country: 'Vietnam',
    code: 'VNM',
    iso: 'VN',
    timezone: 'Asia/Saigon',
    utc: 7,
    flag: '🇻🇳',
    union: false,
  },
  {
    country: 'Wales',
    code: 'WAL',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    union: true,
  },
];

export const farmingCountries = [
  {
    country: 'Denmark',
    code: 'DNK',
    iso: 'DK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇩🇰',
    union: false,
  },
  {
    country: 'England',
    code: 'ENG',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    union: true,
  },
  {
    country: 'Finland',
    code: 'FIN',
    iso: 'FI',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇫🇮',
    union: false,
  },
  {
    country: 'France',
    code: 'FRA',
    iso: 'FR',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇫🇷',
    union: false,
  },
  {
    country: 'Greece',
    code: 'GRC',
    iso: 'GR',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇬🇷',
    union: false,
  },
  {
    country: 'Iceland',
    code: 'ISL',
    iso: 'IS',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇸',
    union: false,
  },
  {
    country: 'Ireland',
    code: 'IRL',
    iso: 'IE',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇪',
    union: false,
  },
  {
    country: 'Italy',
    code: 'ITA',
    iso: 'IT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇮🇹',
    union: false,
  },
  {
    country: 'Netherlands',
    code: 'NLD',
    iso: 'NL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇱',
    union: false,
  },
  {
    country: 'Norway',
    code: 'NOR',
    iso: 'NO',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇴',
    union: false,
  },
  {
    country: 'Portugal',
    code: 'PRT',
    iso: 'PT',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇵🇹',
    union: false,
  },
  {
    country: 'Scotland',
    code: 'SCO',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    union: true,
  },
  {
    country: 'Spain',
    code: 'ESP',
    iso: 'ES',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇪🇸',
    union: false,
  },
  {
    country: 'Sweden',
    code: 'SWE',
    iso: 'SE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇪',
    union: false,
  },
  {
    country: 'Ecuador',
    code: 'ECU',
    iso: 'EC',
    timezone: 'America/Guayaquil',
    utc: -5,
    flag: '🇪🇨',
    union: false,
  },
].concat(originCountries);

export const getCountryFromIso = (iso) => originCountries.find((c) => c.iso === iso);

export const getCountryFromCode = (code) => originCountries.find((c) => c.code === code);

export const getCountryNameFromCode = (countryCode) => {
  const country = originCountries.find((c) => c.code === countryCode);
  return country ? country.country : countryCode;
};

export const getFullAddressFromId = (addresses, companies, id) => {
  const address = addresses.find((a) => a.id === id);
  const company = companies.find((c) => c.id === address.companyId) || null;
  switch (address.type) {
    case addressType.delivery:
      return `Delivery to ${company ? company.name : 'customer'}: ${address.line1} ${
        address.line2
      }...`;
    case addressType.dispatch:
      return `Dispatch from ${company ? company.name : 'supplier'}: ${address.line1} ${
        address.line2
      }...`;
    case addressType.platform:
      return `Platform: ${address.line1} ${address.line2}...`;
    default:
      return `${company ? company.name : 'company'}: ${address.line1} ${address.line2}...`;
  }
};

export const TypesOfChange = {
  priceChangeKgDown: 1,
  priceChangeKgUp: 2,
  cancellation: 3,
  customerMistake: 4,
  quantity: 5,
  rooserBackofficeError: 6,
  descriptionError: 7,
  roundOff: 8,
  wrongClientBilled: 9,
};

export const ReasonsOfChange = {
  delayTransportationSupplier: 1,
  qualityNotCompliant: 2,
  missingBoxes: 3,
  weightNotCompliant: 4,
  variableWeight: 5,
  cutNotCompliant: 6,
  packaging: 7,
  delayTransportationShippingCompany: 8,
  delayPreparation: 9,
  tooManyBoxesReceived: 10,
  deliveryDateNotCompliant: 11,
  doubleOrder: 12,
  qualityIssueDuringCheck: 13,
  nonSendParcelAtBSM: 14,
  errorDescriptionVariableWeight: 15,
  errorDelivery: 16,
  sizeNotCompliant: 17,
  clientNegotiation: 18,
  mistakeDuringPreparation: 19,
  parcelMixedUp: 20,
  other: 21,
};

/**
 *  -----------------------------------------------------------------
 *          Fishing Areas
 *  -----------------------------------------------------------------
 */
export const fishingAreasGrouped = [
  {
    label: '27.4-North Sea',
    options: [
      { code: '27.4.a', area: 'Northern North Sea ' },
      { code: '27.4.b', area: 'Central North Sea ' },
      { code: '27.4.c', area: 'Southern North Sea' },
    ],
  },
  {
    label:
      '27.7-Irish Sea, West of Ireland, Porcupine Bank, Eastern and Western English Channel, etc',
    options: [
      { code: '27.7.a', area: 'Irish Sea' },
      { code: '27.7.b', area: 'West of Ireland' },
      { code: '27.7.c', area: ' Porcupine Bank' },
      { code: '27.7.d', area: 'Eastern English Channel' },
      { code: '27.7.e', area: 'Western English Channel ' },
      { code: '27.7.f', area: 'Bristol Channel ' },
      { code: '27.7.g', area: 'Celtic Sea North' },
      { code: '27.7.h', area: 'Celtic Sea South ' },
      { code: '27.7.j', area: 'Southwest of Ireland - East' },
      { code: '27.7.k', area: 'Southwest of Ireland - West' },
    ],
  },
  {
    label: '27.2-Norwegian Sea, Spitzbergen, and Bear Island',
    options: [
      { code: '27.2.a', area: 'Norwegian Sea ' },
      { code: '27.2.b', area: 'Spitzbergen and Bear Island ' },
    ],
  },
  {
    label: '27.1-Barents Sea',
    options: [
      { code: '27.1.a', area: 'Barents Sea - NEAFC Regulatory Area ' },
      { code: '27.1.b', area: 'Barents Sea Non-NEAFC Regulatory Area' },
    ],
  },
  {
    label: '27.8-Bay of Biscay',
    options: [
      { code: '27.8.a', area: 'Bay of Biscay - North' },
      { code: '27.8.b', area: 'Bay of Biscay - Central' },
      { code: '27.8.c', area: 'Bay of Biscay - South' },
      { code: '27.8.d', area: 'Bay of Biscay - Offshore' },
      { code: '27.8.e', area: 'West of Bay of Biscay' },
    ],
  },
  {
    label: '27.5-Iceland and Faroes Grounds',
    options: [
      { code: '27.5.a', area: 'Iceland Grounds' },
      { code: '27.5.b', area: 'Faroes Grounds' },
    ],
  },
  {
    label: '27.6-Rockall, Northwest Coast of Scotland and North Ireland',
    options: [
      {
        code: '27.6.a',
        area: 'Northwest Coast of Scotland and North Ireland or as the West of Scotland ',
      },
      { code: '27.6.b', area: 'Rockall' },
    ],
  },
  {
    label: '27.3-Skagerrak, Kattegat, Sound, Belt Sea, and Baltic Sea',
    options: [
      { code: '27.3.a', area: 'Skagerrak and Kattegat ' },
      { code: '27.3.b,c', area: 'Sound and Belt Sea or the Transition Area ' },
      { code: '27.3.d', area: 'Baltic Sea' },
    ],
  },
  {
    label: '37.1-Western Mediterranean',
    options: [
      { code: '37.1.1', area: 'Balearic' },
      { code: '37.1.2', area: 'Gulf of Lions' },
      { code: '37.1.3', area: 'Sardinia' },
    ],
  },
  {
    label: '37.2-Central Mediterranean',
    options: [
      { code: '37.2.1', area: 'Adriatic' },
      { code: '37.2.2', area: 'Ionian ' },
    ],
  },
];

export const fishingAreas = [
  { code: '27.1', area: 'Atlantic Northeast' },
  { code: '27.1.a', area: 'Atlantic Northeast' },
  { code: '27.1.b', area: 'Atlantic Northeast' },
  { code: '27.2.a', area: 'Atlantic Northeast' },
  { code: '27.2.b', area: 'Atlantic Northeast' },
  { code: '27.3', area: 'Atlantic Northeast' },
  { code: '27.3.a', area: 'Atlantic Northeast' },
  { code: '27.3.b', area: 'Atlantic Northeast' },
  { code: '27.3.c', area: 'Atlantic Northeast' },
  { code: '27.3.d', area: 'Atlantic Northeast' },
  { code: '27.4', area: 'Atlantic Northeast' },
  { code: '27.4.a', area: 'Atlantic Northeast' },
  { code: '27.4.b', area: 'Atlantic Northeast' },
  { code: '27.4.c', area: 'Atlantic Northeast' },
  { code: '27.5', area: 'Atlantic Northeast' },
  { code: '27.5.a', area: 'Atlantic Northeast' },
  { code: '27.5.b', area: 'Atlantic Northeast' },
  { code: '27.6', area: 'Atlantic Northeast' },
  { code: '27.6.a', area: 'Atlantic Northeast' },
  { code: '27.6.b', area: 'Atlantic Northeast' },
  { code: '27.7', area: 'Atlantic Northeast' },
  { code: '27.7.a', area: 'Atlantic Northeast' },
  { code: '27.7.b', area: 'Atlantic Northeast' },
  { code: '27.7.c', area: 'Atlantic Northeast' },
  { code: '27.7.d', area: 'Atlantic Northeast' },
  { code: '27.7.e', area: 'Atlantic Northeast' },
  { code: '27.7.f', area: 'Atlantic Northeast' },
  { code: '27.7.g', area: 'Atlantic Northeast' },
  { code: '27.7.h', area: 'Atlantic Northeast' },
  { code: '27.7.j', area: 'Atlantic Northeast' },
  { code: '27.7.k', area: 'Atlantic Northeast' },
  { code: '27.8', area: 'Atlantic Northeast' },
  { code: '27.8.a', area: 'Atlantic Northeast' },
  { code: '27.8.b', area: 'Atlantic Northeast' },
  { code: '27.8.c', area: 'Atlantic Northeast' },
  { code: '27.8.d', area: 'Atlantic Northeast' },
  { code: '27.8.e', area: 'Atlantic Northeast' },
  { code: '27.9', area: 'Atlantic Northeast' },
  { code: '27.9.a', area: 'Atlantic Northeast' },
  { code: '27.9.b', area: 'Atlantic Northeast' },
  { code: '27.10', area: 'Atlantic Northeast' },
  { code: '27.10.a', area: 'Atlantic Northeast' },
  { code: '27.10.b', area: 'Atlantic Northeast' },
  { code: '27.12', area: 'Atlantic Northeast' },
  { code: '27.12.a', area: 'Atlantic Northeast' },
  { code: '27.12.b', area: 'Atlantic Northeast' },
  { code: '27.12.c', area: 'Atlantic Northeast' },
  { code: '27.14', area: 'Atlantic Northeast' },
  { code: '27.14.a', area: 'Atlantic Northeast' },
  { code: '27.14.b', area: 'Atlantic Northeast' },
  { code: '21', area: 'Atlantic Northwest' },
  { code: '21.0', area: 'Atlantic Northwest' },
  { code: '21.0.a', area: 'Atlantic Northwest' },
  { code: '21.0.b', area: 'Atlantic Northwest' },
  { code: '21.1', area: 'Atlantic Northwest' },
  { code: '21.1.a', area: 'Atlantic Northwest' },
  { code: '21.1.b', area: 'Atlantic Northwest' },
  { code: '21.1.c', area: 'Atlantic Northwest' },
  { code: '21.1.d', area: 'Atlantic Northwest' },
  { code: '21.1.e', area: 'Atlantic Northwest' },
  { code: '21.1.f', area: 'Atlantic Northwest' },
  { code: '21.2', area: 'Atlantic Northwest' },
  { code: '21.2.g', area: 'Atlantic Northwest' },
  { code: '21.2.h', area: 'Atlantic Northwest' },
  { code: '21.2.j', area: 'Atlantic Northwest' },
  { code: '21.3', area: 'Atlantic Northwest' },
  { code: '21.3.k', area: 'Atlantic Northwest' },
  { code: '21.3.l', area: 'Atlantic Northwest' },
  { code: '21.3.m', area: 'Atlantic Northwest' },
  { code: '21.3.n', area: 'Atlantic Northwest' },
  { code: '21.3.o', area: 'Atlantic Northwest' },
  { code: '21.3.p', area: 'Atlantic Northwest' },
  { code: '21.4', area: 'Atlantic Northwest' },
  { code: '21.4.r', area: 'Atlantic Northwest' },
  { code: '21.4.s', area: 'Atlantic Northwest' },
  { code: '21.4.t', area: 'Atlantic Northwest' },
  { code: '21.4.v', area: 'Atlantic Northwest' },
  { code: '21.4.w', area: 'Atlantic Northwest' },
  { code: '21.4.x', area: 'Atlantic Northwest' },
  { code: '21.5.y', area: 'Atlantic Northwest' },
  { code: '21.5.z', area: 'Atlantic Northwest' },
  { code: '21.6.a', area: 'Atlantic Northwest' },
  { code: '21.6.b', area: 'Atlantic Northwest' },
  { code: '21.6.c', area: 'Atlantic Northwest' },
  { code: '21.6.d', area: 'Atlantic Northwest' },
  { code: '21.6.e', area: 'Atlantic Northwest' },
  { code: '21.6.f', area: 'Atlantic Northwest' },
  { code: '21.6.g', area: 'Atlantic Northwest' },
  { code: '21.6.h', area: 'Atlantic Northwest' },
  { code: '34', area: 'Atlantic Eastern-Central' },
  { code: '34.1', area: 'Atlantic Eastern-Central' },
  { code: '34.1.1', area: 'Atlantic Eastern-Central' },
  { code: '34.1.2', area: 'Atlantic Eastern-Central' },
  { code: '34.1.3', area: 'Atlantic Eastern-Central' },
  { code: '34.2', area: 'Atlantic Eastern-Central' },
  { code: '34.2.0', area: 'Atlantic Eastern-Central' },
  { code: '34.3', area: 'Atlantic Eastern-Central' },
  { code: '34.3.1', area: 'Atlantic Eastern-Central' },
  { code: '34.3.2', area: 'Atlantic Eastern-Central' },
  { code: '34.3.3', area: 'Atlantic Eastern-Central' },
  { code: '34.3.4', area: 'Atlantic Eastern-Central' },
  { code: '34.3.5', area: 'Atlantic Eastern-Central' },
  { code: '34.3.6', area: 'Atlantic Eastern-Central' },
  { code: '34.4', area: 'Atlantic Eastern-Central' },
  { code: '34.4.1', area: 'Atlantic Eastern-Central' },
  { code: '34.4.2', area: 'Atlantic Eastern-Central' },
  { code: '37', area: 'Mediterranean/Black Sea' },
  { code: '37.1', area: 'Mediterranean/Black Sea' },
  { code: '37.1.1', area: 'Mediterranean/Black Sea' },
  { code: '37.1.2', area: 'Mediterranean/Black Sea' },
  { code: '37.1.3', area: 'Mediterranean/Black Sea' },
  { code: '37.2', area: 'Mediterranean/Black Sea' },
  { code: '37.2.1', area: 'Mediterranean/Black Sea' },
  { code: '37.2.2', area: 'Mediterranean/Black Sea' },
  { code: '37.3', area: 'Mediterranean/Black Sea' },
  { code: '37.3.1', area: 'Mediterranean/Black Sea' },
  { code: '37.3.2', area: 'Mediterranean/Black Sea' },
  { code: '37.4', area: 'Mediterranean/Black Sea' },
  { code: '37.4.1', area: 'Mediterranean/Black Sea' },
  { code: '37.4.2', area: 'Mediterranean/Black Sea' },
  { code: '37.4.3', area: 'Mediterranean/Black Sea' },
];

export const getAreaFromCode = (code) => {
  const area = fishingAreas.find((c) => c.code === code);
  return area || null;
};

export const getAreaNameFromCode = (code) => {
  const area = getAreaFromCode(code);
  return area ? `FAO ${code} ${area.area}` : 'N/A';
};

export const getLogoWidth = (certificateId) => {
  const widths = {
    1: 25,
    2: 25,
    3: 25,
    4: 35,
    5: 35,
    6: 32,
    7: 32,
    8: 36,
  };

  return widths[certificateId] ? widths[certificateId] : 31;
};

export const timezones = [
  {
    label: 'GMT-01:00 Azores/Cape Verde',
    value: 'Atlantic/Azores',
    hours: -1,
  },
  {
    label: 'GMT+00:00 London / Lisbon / Reykjavik',
    value: 'Europe/London',
    hours: 0,
  },
  {
    label: 'GMT+01:00 Paris / Madrid / Oslo / Stockholm',
    value: 'Europe/Paris',
    hours: 1,
  },
  {
    label: 'GMT+02:00 Athens / Helsinki',
    value: 'Europe/Athens',
    hours: 2,
  },
  {
    label: 'GMT+03:00 Moscow',
    value: 'Europe/Moscow',
    hours: 3,
  },
];

export const weightDecisionTypes = {
  none: 0,
  supplier: 1,
  platform: 2,
  match: 3,
};

export const weightTypes = [
  { value: 0, text: i18n.t('generic:weightDecisionTypes.none', 'none') },
  {
    value: 1,
    text: i18n.t('generic:weightDecisionTypes.supplier', 'supplier'),
  },
  {
    value: 2,
    text: i18n.t('generic:weightDecisionTypes.platform', 'platform'),
  },
  { value: 3, text: i18n.t('generic:weightDecisionTypes.platform', 'match') },
];

/**
 *  -----------------------------------------------------------------
 *          BACKOFFICE tabs
 *  -----------------------------------------------------------------
 */

export const productsTabs = [
  {
    header: i18n.t('tabs:products.blacklist', 'Blacklist'),
    url: '/products/blacklist',
  },
];

export const logisticTabs = [
  {
    header: i18n.t('tabs:logistics.logistic', 'Logistic'),
    url: '/logistics/logistic',
  },
  {
    header: i18n.t('tabs:logistics.report', 'Reports'),
    url: '/logistics/report',
  },
  {
    header: 'Platforms',
    url: '/logistics/platforms',
  },
  {
    header: i18n.t('tabs:logistics.transports', 'Transports'),
    url: '/logistics/transports',
  },
  {
    header: i18n.t('tabs:logistics.areas', 'Areas'),
    url: '/logistics/areas',
  },
  {
    header: i18n.t('tabs:logistics.generateLabel', 'Generate Label'),
    url: '/logistics/generate-label',
  },
];

export const salesTabs = [
  {
    header: i18n.t('tabs:sales.sales', 'Sales'),
    url: '/sales/sales',
  },
  {
    header: i18n.t('tabs:sales.variableWeights', 'Variable Weight'),
    url: '/sales/variable-weight',
  },
  {
    header: i18n.t('tabs:sales.invoices', 'Invoices'),
    url: '/sales/invoices',
  },
  {
    header: i18n.t('phrase:creditNotes.creditNotes', 'Credit Notes'),
    url: '/sales/credit-notes',
  },
  {
    header: i18n.t('tabs:sales.fxContracts', 'Fx Contracts'),
    url: '/sales/fx-contracts',
  },
];

export const companiesTabs = [
  {
    header: i18n.t('tabs:companies.customers', 'Customers'),
    url: '/accounts/customers',
  },
  {
    header: i18n.t('tabs:companies.suppliers', 'Suppliers'),
    url: '/accounts/suppliers',
  },
];

export const feedbackLink = 'https://forms.gle/vABQz73VKD4vENk49';

export const modalTypes = {
  ROUTES: 'routes',
  PLATFORMS: 'platforms',
  TRUCKS: 'trucks',
  TRANSPORTS: 'transports',
  AREAS: 'areas',
  EDIT_SALES: 'editSales',
  OFFER_DETAILS: 'offerDetail',
  VARIABLE_WEIGHT: 'variableWeight',
  DESTINATION_PICKER: 'destination_picker',
  INVOICE_EDIT_SALE: 'invoice_edit_sale',
  WHATSAPP_SELECT_BUYERS: 'whatsapp_select_buyers',
  WHATSAPP_SELECT_USERS: 'whatsapp_select_users',
};

/**
 *  -----------------------------------------------------------------
 *          BULK DISCOUNTS
 *  -----------------------------------------------------------------
 */

export const bulkDiscountThresholds = [
  { requiredWeight: 100, discountPercent: 1.5 },
  { requiredWeight: 200, discountPercent: 2.5 },
  { requiredWeight: 300, discountPercent: 3.5 },
];

export const priceFormatter = (currency) => new Intl.NumberFormat(i18n.language, {
  style: 'currency',
  currency,
});

export const issueTypes = {
  sales_team_only_price_decrease_negotiation: 'Sales team only:: Price Decrease - Negotiation',
  sales_team_only_price_decrease_bulk_price: 'Sales team only:: Price Decrease - Bulk Price',
  sales_team_only_price_decrease_must_go: 'Sales team only:: Price Decrease - Must Go',
  sales_team_only_price_decrease_commercial_gesture:
    'Sales team only:: Price Decrease - Commercial Gesture',
  sales_team_only_price_increase_any_reason: 'Sales team only:: Price Increase - Any reason',
  sales_team_only_average_price_lower_catalogue_promo:
    'Sales team only:: Average price lower - Catalogue Promo',
  sales_team_only_average_price_higher_catalogue_promo:
    'Sales team only:: Average price higher - Catalogue Promo',
  customer_order_error: 'Customer::Order error',
  customer_accidental_order: 'Customer::Accidental order',
  customer_order_was_delayed: 'Customer::Order was delayed',
  customer_order_didnt_arrive: "Customer::Order didn't arrive",
  customer_order_change_request: 'Customer::Order change request',
  supplier_additional_product_sent: 'Supplier::Additional product sent',
  supplier_missing_boxes: 'Supplier::Missing boxes',
  supplier_wrong_product: 'Supplier::Wrong product',
  supplier_damaged_product: 'Supplier::Damaged product',
  supplier_listing_error: 'Supplier::Listing error',
  supplier_late_delivery: 'Supplier::Late delivery',
  rooser_variable_weight_erro: 'Rooser::Variable weight error',
  rooser_invoice_error: 'Rooser::Invoice error',
  rooser_order_incorrectly_processed: 'Rooser::Order incorrectly processed',
  rooser_commercial_gesture: 'Rooser::Commercial gesture',
  rooser_quality_control_error: 'Rooser::Quality control error',
  logistic_product_not_delivered: 'Logistics::Product not delivered',
  logistic_incorect_product_delivered: 'Logistics::Incorrect product delivered',
  logistic_partial_order_received: 'Logistics::Partial order received',
  logistic_product_damaged_during_preparation: 'Logistics::Product damaged during preparation',
  logistic_late_delivery: 'Logistics::Late delivery',
  logistic_documentation_not_provided: 'Logistics::Documentation not provided',
  logistic_variable_weight_info_error: 'Logistics::Variable weight info error',
  logistic_rooser_error: 'Logistics::Rooser error',
  quality_rejection_freshness: 'Quality rejection::Freshness',
  quality_rejection_label: 'Quality rejection::Label',
  quality_rejection_legal_size: 'Quality rejection::Legal Size',
  quality_rejection_packing: 'Quality rejection::Packaging',
  quality_rejection_parasites: 'Quality rejection::Parasites',
  quality_rejection_presentation: 'Quality rejection::Presentation',
  quality_rejection_temperature: 'Quality rejection::Temperature',
  quality_rejection_grading: 'Quality rejection::Grading',
  quality_issue_freshness: 'Quality issue::Freshness',
  quality_issue_label: 'Quality issue::Label',
  quality_issue_legal_size: 'Quality issue::Legal Size',
  quality_issue_packaging: 'Quality issue::Packaging',
  quality_issue_parasites: 'Quality issue::Parasites',
  quality_issue_presentation: 'Quality issue::Presentation',
  quality_issue_temperature: 'Quality issue::Temperature',
  quality_issue_grading: 'Quality issue::Grading',
  other: 'Other',
};

export const buyerIssues = Object.values(issueTypes).map((value) => ({ value }));
