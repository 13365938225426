import * as ActionTypes from './actionTypes';

const initialState = {
  species: [],
  types: [],
  variations: [],
  grades: [],
  packings: [],
  fishingMethods: [],
  fishingAreas: [],
  certificates: [],
  certifications: [],
  brandings: [],
  transportMethod: [],
  nature: [],
  filter: {
    species: '',
    types: '',
    variations: '',
    grades: '',
    packings: '',
    fishingMethods: '',
  },
  lastFetch: {
    productData: new Date(),
    species: new Date(),
    types: new Date(),
    variations: new Date(),
    grades: new Date(),
    packings: new Date(),
    certifications: new Date(),
    fishingMethods: new Date(),
    certificates: new Date(),
    productCommissions: new Date(),
    brandings: new Date(),
    transportMethod: new Date(),
    nature: new Date(),
  },
  productCommissions: [],
  productsBlacklist: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.RESET_DATA:
      return { ...state, ...initialState };
    case ActionTypes.PRODUCTS_LAST_FETCH:
      return {
        ...state,
        lastFetch: {
          ...state.lastFetch,
          productData: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_CERTIFICATIONS:
      return {
        ...state,
        certifications: payload,
        lastFetch: {
          ...state.lastFetch,
          certifications: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_CERTIFICATES:
      return {
        ...state,
        certificates: payload,
        lastFetch: {
          ...state.lastFetch,
          certificates: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_SPECIES:
      return {
        ...state,
        species: payload,
        lastFetch: {
          ...state.lastFetch,
          species: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_TYPES:
      return {
        ...state,
        types: payload,
        lastFetch: {
          ...state.lastFetch,
          types: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_GRADES:
      return {
        ...state,
        grades: payload,
        lastFetch: {
          ...state.lastFetch,
          grades: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_VARIATIONS:
      return {
        ...state,
        variations: payload,
        lastFetch: {
          ...state.lastFetch,
          variations: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_PACKINGS:
      return {
        ...state,
        packings: payload,
        lastFetch: {
          ...state.lastFetch,
          packings: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_FISHING_METHODS:
      return {
        ...state,
        fishingMethods: payload,
        lastFetch: {
          ...state.lastFetch,
          fishingMethods: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_FISHING_AREAS:
      return {
        ...state,
        fishingAreas: payload,
      };
    case ActionTypes.PRODUCTS_SET_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.PRODUCT_BLACKLIST_SET:
      return { ...state, productsBlacklist: payload };
    case ActionTypes.PRODUCT_BLACKLIST_ADD:
      return {
        ...state,
        productsBlacklist: [...new Set(state.productsBlacklist.concat(payload))],
      };
    case ActionTypes.PRODUCT_BLACKLIST_DELETE:
      return {
        ...state,
        productsBlacklist: state.productsBlacklist.filter((id) => id !== payload),
      };
    case ActionTypes.PRODUCTS_BRANDINGS:
      return {
        ...state,
        brandings: payload,
      };
    case ActionTypes.PRODUCTS_TRANSPORT_METHOD:
      return {
        ...state,
        transportMethod: payload,
        lastFetch: {
          ...state.lastFetch,
          transportMethod: new Date(),
        },
      };
    case ActionTypes.PRODUCTS_NATURE:
      return {
        ...state,
        nature: payload,
        lastFetch: {
          ...state.lastFetch,
          nature: new Date(),
        },
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
